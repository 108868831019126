<template>
  <!-- 村通客运规则配置 -->
  <div class="villagePassConfig">
    <div class="rule-tabs-content">
      <Table
        :table-data="tableData"
        :title-name="titleName"
        :operation="true"
        :col-class-type="true"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            sort="primary"
            @click="editFun(scope.scopeRow)"
            >编辑</el-button
          >
        </template>
      </Table>
      <charterPopup
        v-if="show"
        :header-text="headerText"
        @confirmFun="confirmFormFun"
        @closePopupFun="closePopupFun"
      >
        <el-form
          ref="Form"
          :model="Form"
          :rules="rules"
          label-width="110px"
          label-position="left"
          class="demo-form dialog-form"
        >
          <el-form-item label="规则值：" prop="regulationValue">
            <el-input v-model="Form.regulationValue"></el-input>
          </el-form-item> </el-form
      ></charterPopup>
    </div>
  </div>
</template>

<script>
import { searchRulesAPI, updateRulesAPI } from "@/api/lib/api.js";
export default {
  props: {
    channelNo: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      show: false,
      tableData: [],
      titleName: [
        {
          title: "规则代码",
          props: "regulationCode",
        },
        {
          title: "规则说明",
          props: "regulationName",
        },
        {
          title: "规则值",
          props: "regulationValue",
        },
        {
          title: "单位",
          props: "unitType",
          SpecialJudgment: (res) => {
            let text = "";
            switch (res) {
              case 0:
                text = "秒";
                break;
              case 1:
                text = "分钟";
                break;
              case 2:
                text = "小时";
                break;
              case 3:
                text = "天";
                break;
              case 4:
                text = "米";
                break;
              case 5:
                text = "公里";
                break;
            }
            return text;
          },
        },
      ],
      headerText: "",
      Form: {
        id: "",
        regulationCode: "",
        regulationName: "",
        regulationValue: "",
        unitType: "",
        type: null,
      },
      unitType: [
        {
          value: 0,
          label: "秒",
        },
        {
          value: 1,
          label: "分钟",
        },
        {
          value: 2,
          label: "小时",
        },
        {
          value: 3,
          label: "天",
        },
        {
          value: 4,
          label: "米",
        },
        {
          value: 5,
          label: "公里",
        },
      ],
      rule6: {
        regulationValue: [
          {
            required: true,
            trigger: "change",
            validator: (rule, value, callback) => {
              if (value === "") {
                callback("请输入规则值");
              } else if (value < 100) {
                callback("规则值必须大于100");
              } else if (value > 30000) {
                callback("规则值必须小于30000");
              } else {
                callback();
              }
            },
          },
        ],
      },
    };
  },
  computed: {
    rules() {
      let key = this.Form.id;
      return this[`rule${key}`];
    },
  },
  methods: {
    closePopupFun() {
      this.show = false;
    },
    renderData() {
      if (!this.channelNo) {
        this.$message.error("当前服务器未配置渠道，请先设置！");
        return;
      }
      searchRulesAPI({ channelNo: this.channelNo }).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data.filter((item) => {
            return item.type == 7;
          });
          this.tableData.map((item) => {
            if (item.id == 6) {
              item.unitType = 4;
            }
          });
        }
      });
    },
    confirmFormFun() {
      this.$refs.Form.validate((valid) => {
        if (valid) {
          let obj = this.deepClone(this.Form);
          updateRulesAPI({
            id: obj.id,
            regulationValue: obj.regulationValue,
            type: obj.type,
            channelNo: this.channelNo,
          }).then((res) => {
            if (res.code == "SUCCESS") {
              this.show = false;
              this.$message.success("修改成功");
              this.renderData();
            }
          });
        } else {
          this.show = true;
        }
      });
    },
    editFun(val) {
      this.headerText = "编辑";
      this.show = true;
      console.log(val);
      this.Form = this.deepClone(val);
    },
  },
  watch: {
    channelNo() {
      this.renderData();
    },
  },
  mounted() {
    this.renderData();
  },
};
</script>

<style lang="scss">
.villagePassConfig {
  height: 100%;
  .rule-tabs-content {
    padding: 15px;
    background: #ffffff;
    .tabs-add {
      background: #336fee;
      color: #ffffff;
      margin-bottom: 10px;
    }
  }
}
</style>
